<template>
  <div>

    <!-- 面包 导航-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item >首页</el-breadcrumb-item>
      <el-breadcrumb-item>查看医生信息</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 结果列表 -->
    <el-card class="container">
      <el-table :data="doctorList" stripe style="width: 100%" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">

        <el-table-column prop="icon" label="医生头像" width="100">
          <template slot-scope="scope">
            <el-image style="width: 100px; height: 100px"
                      :src="scope.row.icon"
                      fit="fill">
            </el-image>
          </template>
        </el-table-column>

        <el-table-column prop="name" label="医生姓名" width="100">
          <template slot-scope="scope">
             {{scope.row.name}}
          </template>
        </el-table-column>

        <el-table-column prop="title" label="医生头衔" width="100">
          <template slot-scope="scope">
            {{scope.row.title }}
          </template>
        </el-table-column>

        <el-table-column prop="hospital" label="所在医院" width="100">
          <template slot-scope="scope">
            {{scope.row.hospital}}
          </template>
        </el-table-column>

        <el-table-column prop="profile" label="简介" width="200" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{scope.row.profile}}
          </template>
        </el-table-column>

        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="openEditUI(scope.row)" type="primary" icon="el-icon-edit" size="mini" circle></el-button>
            <el-button @click="deleteOne(scope.row.id)" type="danger" icon="el-icon-delete" size="mini" circle></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 分页组件 -->
    <el-pagination  v-if="total !== 0"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="searchModel.page"
                    :page-sizes="[5, 10, 20, 50]"
                    :page-size="searchModel.limit"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total" >
    </el-pagination>

  </div>
</template>

<script>
export default {
  name: "DoctorInfo",

  data(){
    return{
      doctorList: [],
      total : 0,

      searchModel: {
        page: 1,
        limit: 5
      },

    }
  },
  mounted() {
    this.getDoctorInfoList();
  },

  methods:{

    //是否在优选教师中显示
    async doctorSelectionChanged(info){
      const { data: res } = await this.$http.post('/doctorinfo/update', info)
      console.log("lunBo update-->",res)
      if (res.code !== 2000) {
        this.$notify.error({ title: "错误", message: res.message  });
        info.doctorSelection = !info.doctorSelection
      }else{
        this.$notify.success({ title: "成功",  message: res.message });
      }
    },
    //该教师信息是否展示
    async doctorStatusChanged(info){
      const { data: res } = await this.$http.post('/doctorinfo/update', info)
      console.log("doctorStatus update-->",res)
      if (res.code !== 2000) {
        this.$notify.error({ title: "错误", message: res.message  });
        info.doctorStatus = !info.doctorStatus
      }else{
        this.$notify.success({ title: "成功",  message: res.message });
      }
    },


    getDoctorInfoList() {
      this.$http.get(`/doctorinfo/list/${this.searchModel.page}/${this.searchModel.limit}` )
        .then((response) => {
          console.log("---->",response)
          if(response.data.code !==2000){
            this.$message.error(response.data.message)
            return
          }
          this.doctorList = response.data.data.list;
          this.total = response.data.data.total;
        });
    },

    // 当前分页中展示的item个数
    handleSizeChange(pageSize) {
      this.searchModel.limit = pageSize;
      this.getDoctorInfoList();
    },
    //页面切换（切换到其他分页）
    handleCurrentChange(pageNo) {
      this.searchModel.page = pageNo;
      this.getDoctorInfoList();
    },

    openEditUI(info) {
      this.$router.push({name:"DoctorEdit",params:{
          isEdit:true,
          doctorId: info.id,
        }})
    },

    deleteOne(id){
      this.$confirm(`您确认删除该咨询?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log("======>" ,id)
        this.$http.delete(`/doctorinfo/delete/${id}` ).then(response=>{
          this.$message({
            type: 'success',
            message: response.data.message,
          });
          this.getDoctorInfoList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

  }
}
</script>

<style lang="scss" scoped>
.el-breadcrumb{
  margin-bottom: 20px;
}

.container{
  margin-top: 20px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  padding: 20px;
  background-color: #f9fafc;

}
.myNote {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

</style>

