<template>
  <div class="wrapper">
    <!-- 面包 导航-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>统计分析</el-breadcrumb-item>
      <el-breadcrumb-item>数据报表B</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 结果列表 -->
    <el-card class="container">
      <div class="controls">
        <div>
          <label for="start_date">开始时间:</label>
          <input type="date" v-model="startDate" required>
          <label for="end_date">结束时间:</label>
          <input type="date" v-model="endDate" required>
        </div>
        <div>
          <button @click="fetchData">查询</button>
          <button @click="exportToExcel">导出</button>
        </div>
      </div>

      <div class="table-container">
        <div class="table-scroll">
          <table ref="dataTable">
            <thead>
              <tr>
                <th v-for="header in headers" :key="header">{{ header }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, rowIndex) in tableData" :key="rowIndex">
                <td v-for="header in headers" :key="header">{{ row[header] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import CryptoJS from 'crypto-js';
import * as XLSX from 'xlsx';

export default {
    data() {
      return {
        startDate: '',
        endDate: '',
        headers: [],
        tableData: []
      };
    },
    mounted() {
      const today = new Date();
      const thirtyDaysAgo = new Date(today);
      thirtyDaysAgo.setDate(today.getDate() - 30);

      this.endDate = this.formatDateToInput(today);
      this.startDate = this.formatDateToInput(thirtyDaysAgo);
    },
    methods: {
      formatDateToInput(date) {
          const year = date.getFullYear();
          const month = ('0' + (date.getMonth() + 1)).slice(-2);
          const day = ('0' + date.getDate()).slice(-2);
          return `${year}-${month}-${day}`;
        },
    async fetchData() {
      const startDate = this.formatDate(this.startDate);
      const endDate = this.formatDate(this.endDate);
      const url = this.$REPORT_URL+`reportapi_2?start_date=${startDate}&end_date=${endDate}`;

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const jsonResponse = await response.json();
        const encryptedData = jsonResponse.data;
        const key = 'bGFuZXllY2xvdWRpbWFnZQ==';
        const decryptedData = this.decryptAES(encryptedData, key);

        this.loadTableData(decryptedData);
      } catch (error) {
        console.error('Error:', error);
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      return `${year}${month}${day}`;
    },
    decryptAES(encryptedData, key) {
      try {
        const keyHex = CryptoJS.enc.Utf8.parse(key);
        const encrypted = CryptoJS.enc.Base64.parse(encryptedData);
        const iv = CryptoJS.lib.WordArray.create(encrypted.words.slice(0, 4));
        const ciphertext = CryptoJS.lib.WordArray.create(encrypted.words.slice(4));

        const decrypted = CryptoJS.AES.decrypt(
          { ciphertext: ciphertext },
          keyHex,
          { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
        );

        const decryptedStr = decrypted.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedStr);
      } catch (e) {
        console.error('Decryption error:', e);
        return null;
      }
    },
    loadTableData(data) {
      this.headers = Object.keys(data);
      const rows = data[this.headers[0]].length;
      this.tableData = [];

      for (let i = 0; i < rows; i++) {
        const row = {};
        this.headers.forEach(header => {
          row[header] = data[header][i];
        });
        this.tableData.push(row);
      }
    },
    exportToExcel() {
      const table = this.$refs.dataTable;
      const workbook = XLSX.utils.table_to_book(table);
      XLSX.writeFile(workbook, '数据报表B.xlsx');
    }
  }
};
</script>

<style scoped>
.el-breadcrumb{
    margin-bottom: 10px;
  }

body, html {
  height: 100%;
  margin: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.controls > div {
  display: flex;
  align-items: center;
}

.controls label, .controls input, .controls button {
  margin: 5px 10px;
}

label {
  font-weight: bold;
}

input[type="date"] {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 16px;
}

button {
  background-color: #1a73e8;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #0c5bcd;
}

.table-container {
  flex: 1;
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.table-scroll {
  width: 100%;
  max-height: 650px; /* 最大高度，超出部分滚动 */
  overflow-y: auto;
  position: relative;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

thead th {
  position: sticky;
  top: 0;
  background-color: #f1f1f1;
  z-index: 1;
}

table, th, td {
  border: 1px solid #ddd;
}

th, td {
  padding: 12px;
  text-align: center;
}

th {
  background-color: #f1f1f1;
}

@media (max-width: 768px) {
  .controls {
    flex-direction: column;
    align-items: flex-start;
  }

  .controls > div {
    margin-bottom: 10px;
  }

  button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .controls {
    align-items: stretch;
  }

  .controls > div {
    width: 100%;
  }

  .controls label, .controls input, .controls button {
    width: calc(100% - 20px);
    margin: 5px 10px;
  }
}
</style>
