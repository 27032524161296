
<template>
  <div>
    <!-- 面包 导航-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item >首页</el-breadcrumb-item>
      <el-breadcrumb-item>添加医生信息</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="container">
      <el-form label="添加医生信息" :model="form" prop="form"  :inline="true" :rules="rules" ref="form">


        <!-- 医生头像 Authorization   为后端定义 与后端交互，必须传token-->
        <el-form-item  prop="icon" label="医生头像" label-width="120px">
          <el-upload
            class="avatar-uploader"
            :file-list="fileList"
            :action="uploadPicUrl"
            :headers="{'Authorization':  token}"
            :show-file-list="false"
            :on-success="handleAvatarSuccess" >
            <img v-if="form.icon" :src="form.icon" class="avatar-uploader-icon">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item><br>

        <!-- 医生姓名-->
        <el-form-item prop="name" label="医生姓名"label-width="120px">
          <el-input style="width: 600px;" v-model="form.name"  autocomplete="off" placeholder="请输入医生姓名"></el-input>
        </el-form-item><br>

        <el-form-item prop="title" label="医生职称" label-width="120px" >
          <el-input style="width: 600px;" v-model="form.title"  ></el-input>
        </el-form-item><br>

        <el-form-item prop="hospital" label="所在医院"label-width="120px">
          <el-input style="width: 600px;" v-model="form.hospital"   placeholder="请输入医生工作单位"></el-input>
        </el-form-item><br>

        <el-form-item prop="profile" label="医生简介"label-width="120px">
          <el-input style="width: 600px;" v-model="form.profile"   placeholder="请输入医生简介"></el-input>
        </el-form-item><br>


        <el-form-item prop="detailability" label="专业擅长"label-width="120px">
          <el-input style="width: 600px;" v-model="form.detailability"   placeholder="请输入医生专业擅长"></el-input>
        </el-form-item><br>

        <el-form-item prop="detailprofile" label="医生介绍"label-width="120px">
          <el-input style="width: 600px;" v-model="form.detailprofile"   placeholder="请输入医生介绍"></el-input>
        </el-form-item><br>

      </el-form>

      <!-- 表单提交和取消-->
      <div style="text-align:center;" >
        <el-button @click="cancel('form')">取 消</el-button>
        <el-button type="primary" @click="submit('form')">提 交</el-button>
      </div>

    </div>



  </div>
</template>

<script>
import Editor from "@/components/editor";

export default {
  name: "DoctorEdit",
  components: {Editor},
  data() {
    // 验证手机号的规则
    var checkMobile = (rule, value, cb) => {
      const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
      if (regMobile.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的手机号'))
    }

    return {
      options: [{
        value: '0',
        label: '仅线上'
      }, {
        value: '1',
        label: '仅线下'
      }, {
        value: '2',
        label: '线上线下都可以'
      }],
      value:"",
      currentDoctorId:"",
      uploadPicUrl : this.$BASE_URL_SYS+"upload/pic",
      isEdit :false,
      token:"",
      fileList:[],

      //定义规则，判断是否有输入标题
      rules: {
        name: [
          {
            required: true,
            message: "请输入医生姓名",
            trigger: "blur"
          }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
          ]
      },

      //表单 中的信息
      form:{  },

    }
  },
  created() {
    this.token = window.sessionStorage.getItem('token')

  },
  mounted() {
    this.currentDoctorId = this.$route.params.doctorId ;
    this.isEdit = this.$route.params.isEdit
    if(this.isEdit){
      this.getDoctorInfoByid()
    }
  },

  methods: {
    getDoctorInfoByid() {
      this.$http.get(`/doctorinfo/getone/${this.currentDoctorId}` )
        .then((response) => {
          if(response.data.code !==2000){
            this.$message.error(response.data.message)
          }
          this.form = response.data.data
        });
    },
    handleAvatarSuccess(res, file) {
      console.log()
      this.form.icon = this.$BASE_URL+res.data;
      this.fileList.push([this.form.icon])
    },
    getMinWidth(){
      return (this.screenWidth -900)+"px"
    },
    //注意引入 Upload
    //对上传图的图片进行预处理
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('只能上传图片文件');
      }
      if (!isLt2M) {
        this.$message.error('图片大小不能超过 2MB!');
      }
    },


    cancel(formName) {
      this.$refs[formName].resetFields();
      this.fileList.push([])
    },

    submit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if(this.isEdit){
            this.$http.post('/doctorinfo/update', this.form)
              .then(res => {
                if (res.data.code === 2000) {
                  this.$notify.success({
                    title: "成功",
                    message: res.data.message
                  });
                  this.cancel('form');
                }else {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message
                  });
                }
              }).catch((err) =>{
              console.log("提交咨询数据错误:",err);
            })
          }
          else{
            this.$http.post('/doctorinfo/add', this.form)
              .then(res => {
                console.log("-->",res)
                if (res.data.code === 2000) {
                  this.$notify.success({
                    title: "成功",
                    message: res.data.message
                  });
                  this.cancel('form');
                }else {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message
                  });
                }
              }).catch((err) =>{
              console.log("提交咨询数据错误:",err);
            })

          }
        }
      });
    },

  }
}


</script>
<style lang="scss" scoped>
.el-breadcrumb{
  margin-bottom: 20px;
}
.container{
  margin-top: 20px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9fafc;

}

.avatar-uploader {
  border: 1px dashed #d9d9d9;
  margin-top: 40px;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
}
.avatar-uploader-icon {
  display: flex;
  border: 1px dashed #d9d9d9;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  font-size: 30px;
  height: 163px;
  width: 178px;
}
.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader:hover {
  border-color: #409EFF;
}
.el-upload:hover {
  border-color: #409EFF;
}


</style>
