<template>
  <div>

    <!-- 面包 导航-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item >首页</el-breadcrumb-item>
      <el-breadcrumb-item>医生认证管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 结果列表 -->
    <el-card class="container">
      <el-table :data="doctorList" stripe style="width: 100%" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">

        <el-table-column prop="name" label="姓名" width="200">
          <template slot-scope="scope">
            {{scope.row.name}}
          </template>
        </el-table-column>

        <el-table-column prop="phone" label="电话" width="200">
          <template slot-scope="scope">
            {{scope.row.phone }}
          </template>
        </el-table-column>


        <el-table-column prop="isdoctor" label="是否是医生" width="200">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.isdoctor"  @change="doctorStatusChanged(scope.row,true)"> </el-switch>
          </template>
        </el-table-column>


        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button @click="deleteOne(scope.row.id)" type="danger" icon="el-icon-delete" size="mini" circle></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 分页组件 -->
    <el-pagination  v-if="total !== 0"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="searchModel.page"
                    :page-sizes="[5, 10, 20, 50]"
                    :page-size="searchModel.limit"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total" >
    </el-pagination>

  </div>
</template>

<script>
export default {
  name: "AuthenManager",

  data(){
    return{
      doctorList: [],
      total : 0,

      searchModel: {
        page: 1,
        limit: 5
      },

    }
  },
  mounted() {
    this.getDoctorInfoList();
  },

  methods:{
    doctorStatusChanged(info,isStatus){
      this.$http.post('/Alluser/update', info).then(res=>{
        console.log("studentInfo update-->",res)
        if (res.data.code !== 2000) {
          this.$notify.error({ title: "错误", message: res.data.message  });
          if(isStatus){
            info.isdoctor = !info.isdoctor
          }
        }else{
          this.$notify.success({ title: "成功",  message: res.data.message });
        }
      })
    },


    getDoctorInfoList() {
      this.$http.get(`/Alluser/list/${this.searchModel.page}/${this.searchModel.limit}` )
        .then((response) => {

          if(response.data.code !==2000){
            if(response.data.code !==2002) {
              this.$message.error(response.data.message)
            }
            this.doctorList =[]
            this.total = 0
            return
          }
          this.doctorList = response.data.data.list;
          console.log("---->",this.doctorList)
          this.total = response.data.data.total;
        });
    },

    // 当前分页中展示的item个数
    handleSizeChange(pageSize) {
      this.searchModel.limit = pageSize;
      this.getDoctorInfoList();
    },
    //页面切换（切换到其他分页）
    handleCurrentChange(pageNo) {
      this.searchModel.page = pageNo;
      this.getDoctorInfoList();
    },

    deleteOne(id){
      this.$confirm(`您确认删除?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log("======>" ,id)
        this.$http.delete(`/Alluser/delete/${id}` ).then(response=>{
          this.$message({
            type: 'success',
            message: response.data.message,
          });
          this.getDoctorInfoList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

  }
}
</script>

<style lang="scss" scoped>
.el-breadcrumb{
  margin-bottom: 20px;
}

.container{
  margin-top: 20px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  padding: 20px;
  background-color: #f9fafc;

}

</style>

