import { render, staticRenderFns } from "./DataSta.vue?vue&type=template&id=06a63070&scoped=true&"
import script from "./DataSta.vue?vue&type=script&lang=js&"
export * from "./DataSta.vue?vue&type=script&lang=js&"
import style0 from "./DataSta.vue?vue&type=style&index=0&id=06a63070&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06a63070",
  null
  
)

export default component.exports