<template>
  <div>
    <!-- 面包 导航-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>信息管理</el-breadcrumb-item>
      <el-breadcrumb-item>基本信息管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-button type="primary" @click="modifypassword">修改后台登录密码</el-button>


    <!-- 修改用户的对话框 -->
    <el-dialog title="修改用户" :visible.sync="editDialogVisible" width="50%" >
      <!-- 表单内容 -->
      <el-form  ref="editFormRef" label-width="70px">
        <el-form-item label="新密码">
          <el-input v-model="password" prefix-icon="iconfont icon-user"> </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUserInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'password',
  data(){
    return {
      editDialogVisible: false,
      password:"",
    }
  },
  methods:{
    modifypassword(){
      this.editDialogVisible = true
    },

    // 修改用户信息并提交
    editUserInfo() {
      this.$http.post('/user/update', {"userName":"admin","password":this.password})
        .then(res => {
          if (res.data.code === 2000) {
            this.$notify.success({
              title: "成功",
              message: res.data.message
            });
            this.password = ""
            this.editDialogVisible = false
          }else {
            this.$notify.error({
              title: "错误",
              message: res.data.message
            });
          }
        }).catch((err) =>{
        console.log("更新密码数据错误:",err);
      })

    },
  },

}
</script>

<style lang="scss" scoped>
  .el-breadcrumb{
    margin-bottom: 10px;
  }
</style>
