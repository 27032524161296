
<template>
  <div>
    <!-- 面包 导航-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item >首页</el-breadcrumb-item>
      <el-breadcrumb-item to="/lunbo">轮播图管理</el-breadcrumb-item>
      <el-breadcrumb-item>轮播图添加</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="container">
      <el-form label="添加轮播图片" :model="form" prop="form"  :inline="true"   ref="form">

        <!-- 上传封面图片 Authorization   为后端定义 与后端交互，必须传token-->
        <el-form-item  prop="lunboUrl"  label-width="90px">
          <el-upload
            class="avatar-uploader"
            :file-list="fileList"
            :action= uploadPicUrl
            :headers="{'Authorization':  token}"
            :show-file-list="false"
            :on-success="handleAvatarSuccess" >
            <img v-if="form.lunboUrl" :src="form.lunboUrl" class="avatar-uploader-icon">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item><br>

      </el-form>

      <!-- 表单提交和取消-->
      <div style="text-align:center;" >
        <el-button @click="cancel('form')">取 消</el-button>
        <el-button type="primary" @click="submit('form')">提 交</el-button>
      </div>

    </div>

  </div>
</template>

<script>

export default {
  name: "LunboEdit",
  data() {
    return {
      uploadPicUrl : this.$BASE_URL_SYS+"upload/pic",
      isEdit :false,
      token:"",
      fileList: [],
      //表单 中的信息
      form:{
        lunboUrl:"",
        lunboStatus: 0,
      },

    }
  },
  created() {
    this.token = window.sessionStorage.getItem('token')

  },
  mounted() {
    this.form = this.$route.params ;
    this.isEdit = this.$route.params.isEdit
  },

  methods: {
    handleAvatarSuccess(res, file) {
      this.form.lunboUrl =  this.$BASE_URL+res.data;
      this.fileList.push([this.form.lunboUrl])
      console.log("--->" , this.form.lunboUrl)
    },
    getMinWidth(){
      return (this.screenWidth -900)+"px"
    },
    //注意引入 Upload
    //对上传图的图片进行预处理
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('只能上传图片文件');
      }
      if (!isLt2M) {
        this.$message.error('图片大小不能超过 2MB!');
      }
    },


    cancel(formName) {
      this.$refs[formName].resetFields();
      this.fileList.push([])
    },

    submit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if(this.form.lunboUrl.length<=0){
            this.$message.error("请选择图片")
            return
          }
          if(this.isEdit){
            this.$http.post('/lunbo/update', this.form)
              .then(res => {
                console.log("-->",res)
                if (res.data.code === 2000) {
                  this.$notify.success({
                    title: "成功",
                    message: res.data.message
                  });
                  this.cancel('form');
                }else {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message
                  });
                }
              }).catch((err) =>{
              console.log("提交咨询数据错误:",err);
            })
          }
          else{
            this.$http.post('/lunbo/add', this.form)
              .then(res => {
                console.log("-->",res)
                if (res.data.code === 2000) {
                  this.$notify.success({
                    title: "成功",
                    message: res.data.message
                  });
                  this.cancel('form');
                }else {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message
                  });
                }
              }).catch((err) =>{
              console.log("提交咨询数据错误:",err);
            })

          }
        }
      });
    },

  }
}


</script>
<style lang="scss" scoped>
  .el-breadcrumb{
    margin-bottom: 20px;
  }

  .container{
    margin-top: 20px;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
    padding: 20px;
    background-color: #f9fafc;

  }

.avatar-uploader {
  border: 1px dashed #d9d9d9;
  margin-top: 40px;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
}
.avatar-uploader-icon {
  display: flex;
  border: 1px dashed #d9d9d9;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  font-size: 30px;
  height: 400px;
  width: 500px;
}
.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader:hover {
  border-color: #409EFF;
}
.el-upload:hover {
  border-color: #409EFF;
}


</style>
