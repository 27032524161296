<template>
    <div>
      <!-- 面包 导航-->
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>统计分销</el-breadcrumb-item>
        <el-breadcrumb-item>数据统计</el-breadcrumb-item>
      </el-breadcrumb>
  
      <!-- 结果列表 -->
      <el-card class="container">
        <div class="controls">
          <div class="controls-left">
            <label for="start_date">选择日期:</label>
            <input type="date" v-model="startDate" required />
            <span> -- </span>
            <input type="date" v-model="endDate" required />
          </div>
          <button @click="fetchData">查询</button>
        </div>
  
        <div class="charts-container">
          <div class="data-stats">
            <p>总访问量：<span>{{ typeData.TotalVisits }}</span></p>
            <p>平均每日访问量：<span>{{ typeData.AverageDailyVisits }}</span></p>
            <p>总单日最大访问量：<span>{{ typeData.MaxSingleDayVisits }}</span></p>
            <p>检查报告访问量：<span>{{ typeData.ReportVisits }}</span></p>
            <p>检查影像访问量：<span>{{ typeData.ImageVisits }}</span></p>
            <p>检验报告访问量：<span>{{ typeData.ExaminationReportVisits }}</span></p>
          </div>
          <div ref="radarChart" class="chart"></div>
          <div ref="roseChart" class="chart"></div>
        </div>
        <div class="charts-container">
          <div class="full-width-chart">
            <div ref="barChart" style="width: 100%; height: 100%;"></div>
            <div id="chart-unit" class="chart-unit">{{ chartUnit }}</div>
          </div>
        </div>
      </el-card>
    </div>
  </template>
  
  <script>
  import * as echarts from 'echarts';
  
  export default {
    data() {
      return {
        startDate: '',
        endDate: '',
        typeData: {
          TotalVisits: 0,
          AverageDailyVisits: 0,
          MaxSingleDayVisits: 0,
          ReportVisits: 0,
          ImageVisits: 0,
          ExaminationReportVisits: 0,
        },
        chartUnit: '',
      };
    },
    mounted() {
      this.setDefaultDates();
      this.initializeCharts();
      this.fetchData();
    },
    methods: {
      setDefaultDates() {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 30);
  
        this.startDate = this.formatDateInput(startDate);
        this.endDate = this.formatDateInput(endDate);
      },
      async fetchData() {
        const startDate = this.formatDate(this.startDate);
        const endDate = this.formatDate(this.endDate);
        const url = this.$REPORT_URL+`data_stat?start_date=${startDate}&end_date=${endDate}`;
  
        try {
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
          });
  
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const jsonResponse = await response.json();
          const typeData = jsonResponse.data.TypeData;
  
          // 更新数据统计信息
          this.typeData = {
            TotalVisits: typeData.TotalVisits,
            AverageDailyVisits: typeData.AverageDailyVisits,
            MaxSingleDayVisits: typeData.MaxSingleDayVisits,
            ReportVisits: typeData.ReportVisits,
            ImageVisits: typeData.ImageVisits,
            ExaminationReportVisits: typeData.ExaminationReportVisits,
          };
  
          // 计算雷达图的最大值
          const maxVal = Math.max(
            typeData.TotalVisits,
            typeData.AverageDailyVisits,
            typeData.MaxSingleDayVisits,
            typeData.ReportVisits,
            typeData.ImageVisits,
            typeData.ExaminationReportVisits,
          );
  
          // 更新雷达图
          this.radarChart.setOption({
            radar: {
              indicator: [
                { name: '总访问量', max: maxVal },
                { name: '平均每日访问量', max: maxVal },
                { name: '总单日最大访问量', max: maxVal },
                { name: '检查报告访问量', max: maxVal },
                { name: '检查影像访问量', max: maxVal },
                { name: '检验报告访问量', max: maxVal },
              ],
            },
            series: [
              {
                data: [
                  {
                    value: [
                      typeData.TotalVisits,
                      typeData.AverageDailyVisits,
                      typeData.MaxSingleDayVisits,
                      typeData.ReportVisits,
                      typeData.ImageVisits,
                      typeData.ExaminationReportVisits,
                    ],
                    name: '访问量',
                  },
                ],
              },
            ],
          });
  
          // 更新玫瑰图
          const statData = jsonResponse.data.StatData;
          const roseData = Object.keys(statData).map((key) => ({
            value: statData[key],
            name: key,
          }));
          this.roseChart.setOption({
            series: [
              {
                data: roseData,
              },
            ],
          });
  
          // 更新柱状图
          const dateData = jsonResponse.data.DateData;
          this.updateBarChart(dateData);
  
          // 更新图表单位
          this.chartUnit = `单位: ${dateData.unit}`;
        } catch (error) {
          console.error('Error:', error);
        }
      },
      formatDateInput(date) {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
      },
      formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}${month}${day}`;
      },
      initializeCharts() {
        this.radarChart = echarts.init(this.$refs.radarChart);
        this.radarChart.setOption({
          tooltip: {},
          radar: {
            indicator: [
              { name: '总访问量', max: 1000000 },
              { name: '平均每日访问量', max: 1000000 },
              { name: '总单日最大访问量', max: 1000000 },
              { name: '检查报告访问量', max: 1000000 },
              { name: '检查影像访问量', max: 1000000 },
              { name: '检验报告访问量', max: 1000000 },
            ],
            name: {
              textStyle: {
                color: '#000000',
              },
            },
          },
          series: [
            {
              name: '访问量',
              type: 'radar',
              data: [
                {
                  value: [0, 0, 0, 0, 0, 0],
                  name: '访问量',
                },
              ],
            },
          ],
        });
  
        this.roseChart = echarts.init(this.$refs.roseChart);
        this.roseChart.setOption({
          tooltip: {
            trigger: 'item',
          },
          legend: {
            top: 'bottom',
            show: false,
          },
          series: [
            {
              type: 'pie',
              radius: '80%',
              center: ['50%', '50%'],
              roseType: 'radius',
              itemStyle: {
                borderRadius: 8,
              },
              data: [],
            },
          ],
        });
  
        this.barChart = echarts.init(this.$refs.barChart);
        this.barChart.setOption({
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          xAxis: {
            type: 'category',
            data: [],
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              type: 'bar',
              data: [],
              itemStyle: {
                color: '#00a9a9',
              },
            },
          ],
        });
      },
      updateBarChart(dateData) {
        const dates = Object.keys(dateData.Data);
        const values = Object.values(dateData.Data);
        this.barChart.setOption({
          xAxis: {
            data: dates,
          },
          series: [
            {
              data: values,
            },
          ],
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .el-breadcrumb{
    margin-bottom: 10px;
  }

  body {
    font-family: Arial, sans-serif;
    background-color: #f0f4f8;
    color: #333;
    padding: 20px;
    margin: 0;
    box-sizing: border-box;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    height: auto;
  }
  
  .controls {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f6f7f9;
    border-radius: 4px;
  }
  
  .controls-left {
    display: flex;
    align-items: center;
  }
  
  .controls label,
  .controls input {
    margin: 5px 10px;
  }
  
  label {
    font-weight: bold;
  }
  
  input[type="date"] {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 16px;
  }
  
  button {
    background-color: #1a73e8;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-left: 20px;
  }
  
  button:hover {
    background-color: #0c5bcd;
  }
  
    .charts-container {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: stretch;
    gap: 10px;
    overflow: hidden;
    height: 100%;
    }

  
  .chart {
    flex: 1;
    width: 400px;
    display: flex;
    flex-direction: column;
    background-color: #f6f7f9;
    border-radius: 4px;
  }
  
  .data-stats {
    width: 15%;
    background-color: #f6f7f9;
    border-radius: 4px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  
  .data-stats p {
    margin: 0;
  }
  
  .full-width-chart {
    width: 110%;
    height: 400px;
    position: relative;
    margin-top: 10px;
    background-color: #f6f7f9;
    border-radius: 4px;
  }
  
  .chart-unit {
    position: absolute;
    top: 10px;
    font-size: 19px;
    color: #333;
  }
  </style>
  