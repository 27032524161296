<template>

  <el-container class="home-container">
    <!--1. 头部区域 -->
    <el-header>
      <div>
        <img src="../assets/hospital_logo.png" style="width: 300px;
        height: 50px;margin-left: 20px;background-color: aliceblue;
        border-radius: 5px;"></img>
        <span>医疗后台管理系统</span>
      </div>
      <el-button type="info" @click="logout"> 退出</el-button>
    </el-header>
    <!-- 2. 页面主体区域 -->
    <el-container>
      <!--2.1 左边菜单区域 -->
      <el-aside width="200px">
        <!-- 菜单区域 :router表示启动路由模式，当激活导航时以 index 作为 path 进行路由跳转-->
        <el-menu :default-active="activePath" :router="true" :unique-opened="true" background-color="#545c64" text-color="#fff"  active-text-color="#409EFF">
          <!-- 一级菜单   :index 动态的数据绑定-->
          <el-submenu :index="item.id+''" v-for="item in menlist" :key="item.id">
            <!-- 一级菜单中的内容-->
            <template slot="title">
              <!-- 文本-->
              <span>{{item.authName}}</span>
            </template>
            <!-- 二级菜单-->
            <el-menu-item  @click="saveNavState( subItem.path)" :index="subItem.path" v-for="subItem in item.children" :key="subItem.id">
              <template slot="title">
                <!-- 图标 属性前加个: 表示动态绑定(:就是v-bind的缩写）-->
                <i :class="iconsObj[subItem.id]"></i>
                <!-- 文本-->
                <span>{{ subItem.authName }}</span>
              </template>
            </el-menu-item>

          </el-submenu>

        </el-menu>
      </el-aside>
      <!-- 2.2 右边区域 -->
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>

  </el-container>

</template>

<script>
export default {
  name: 'Home',
  data(){
    return{
      //左侧菜单数据
      menlist:[
        {
          id:"1",
          authName: "医生信息管理",
          children:[
            {
              id:"11",
              authName: "查看医生信息",
              path:"DoctorInfo"
           },
            {
              id:"12",
              authName: "添加医生信息",
              path:"DoctorEdit"
            },
          ]
        },
        {
          id:"2",
          authName: "用户管理",
          children:[
            {
              id:"21",
              authName: "所有用户管理",
              path:"AllUserManager"
            },
            {
              id:"22",
              authName: "医生认证管理",
              path:"AuthenManager"
            },
          ]
        },
        {
          id:"3",
          authName: "患者信息管理",
          children:[
            {
              id:"31",
              authName: "检查信息",
              path:"zhixunMain"
            },
            {
              id:"32",
              authName: "检验信息",
              path:"zhixunEdit"
            },
          ],
          path:"zhixunMain"
        },
        {
          id:"4",
          authName: "信息管理",
          children:[
            {
              id:"41",
              authName: "轮播图管理",
              path:"lunbo"
            },
            {
              id:"42",
              authName: "登录密码管理",
              path:"password"
            },
            {
              id:"43",
              authName: "教程管理",
              path:"contactMe"
            },
            {
              id:"44",
              authName: "医院简介",
              path:"hospital"
            },
          ]
        },
        {
          id:"5",
          authName: "统计分析",
          children:[
            {
              id:"51",
              authName: "数据统计",
              path:"DataSta"
            },
            {
              id:"52",
              authName: "数据报表A",
              path:"Report_1"
            },
            {
              id:"53",
              authName: "数据报表B",
              path:"Report_2"
            },
            {
              id:"54",
              authName: "数据报表C",
              path:"Report_3"
            },
            {
              id:"55",
              authName: "数据报表D",
              path:"Report_4"
            },
            {
              id:"56",
              authName: "数据报表E",
              path:"Report_5"
            },
          ]
        },
        ],
      //左侧菜单对应的图标
      iconsObj: {
        '11': 'iconfont icon-user',
        '12': 'iconfont icon-user',
        '21': 'iconfont icon-user',
        '22': 'iconfont icon-user',
        '31': 'iconfont icon-user',
        '32': 'iconfont icon-user',
        '41': 'iconfont icon-shangpin',
        '42': 'iconfont icon-danju',
        '43': 'iconfont icon-danju',
        '44': 'iconfont icon-danju',
        '51': 'iconfont icon-danju',
        '52': 'iconfont icon-danju',
        '53': 'iconfont icon-danju',
        '54': 'iconfont icon-danju',
        '55': 'iconfont icon-danju',
        '56': 'iconfont icon-danju',
      },
      activePath: "",

    }
  },
  methods: {
    logout () {
      window.sessionStorage.clear()
      this.$router.push('/login')
    },

    //子菜单被点击的时候调用
    saveNavState(activePath){
      window.sessionStorage.setItem("activePath",activePath)
      this.activePath = activePath

    },
  },
  created () {
    this.activePath = window.sessionStorage.getItem("activePath")
  }
}
</script>

<style lang="scss" scoped>
.home-container {
  height: 100%;

  .el-header {
    background-color: #373d41;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0px;
    color: #fff;
    font-size: 30px;

    > div {
      display: flex;
      align-items: center;

      > span {
        padding-left: 15px;
      }
    }
  }

  .el-aside {
    background-color: #333744;
    .el-menu{
      border-right: none;
    }
  }

  .el-main {
    background-color: #eaedf1;
  }
  .iconfont{
    margin-right: 10px;
  }
  .toggle-collapse{
    background-color: #4a5064;
    font-size: 10px;
    line-height: 24px;
    color: #fff;
    text-align: center;
    letter-spacing: 0.2em;
    cursor: pointer;
  }
}

</style>
