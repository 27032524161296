
<template>
  <div>
    <!-- 面包 导航-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item >首页</el-breadcrumb-item>
      <el-breadcrumb-item>医院简介</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="container" >
      <el-form label="添加教师信息" :model="form" prop="form"  :inline="true"   ref="form">

        <el-form-item  prop="logo" label="医院logo" label-width="120px">
        <el-upload
          class="avatar-uploader"
          :file-list="fileList"
          :action="uploadPicUrl"
          :headers="{'Authorization':  token}"
          :show-file-list="false"
          :on-success="handleAvatarSuccess" >
          <img v-if="form.logo" :src="form.logo" class="avatar-uploader-icon">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        </el-form-item><br>

        <!-- 老师姓名-->
        <el-form-item prop="name" label="医院名称"label-width="120px">
          <el-input style="width: 600px;" v-model="form.name"  autocomplete="off" placeholder="请输入医院名称"></el-input>
        </el-form-item><br>

        <el-form-item prop="address" label="医院地址" label-width="120px"  >
          <el-input style="width: 600px;" v-model="form.address" placeholder="请输入医院地址"  ></el-input>
        </el-form-item><br>

        <el-form-item prop="keywords" label="医院关键词" label-width="120px"  >
          <el-input style="width: 600px;" v-model="form.keywords" placeholder="请输入医院关键词以-分割多个关键词"  ></el-input>
        </el-form-item><br>


        <el-form-item prop="content" label="医院介绍" label-width="120px" >
          <editor ref="wang_editor" v-model:value="form.content"  ></editor>
        </el-form-item><br>
      </el-form>

    </div>
    <el-button type="primary" @click="submit">提交</el-button>


  </div>
</template>

<script>
import Editor from "@/components/editor";

export default {
  name: "contactMe",
  components: {Editor},
  data() {
    return {
      form:{},
      uploadPicUrl : this.$BASE_URL_SYS+"upload/pic",
      token:"",
      fileList:[],

    }
  },
  created() {
    this.token = window.sessionStorage.getItem('token')

    this.getCurrent()
  },

  methods: {
    handleAvatarSuccess(res, file) {
      this.form.logo = this.$BASE_URL+res.data;
      this.fileList.push([this.form.logo])
    },


    getCurrent(){
      this.$http.get('/hospital/getone', )
        .then(res => {
          console.log("-->",res)
          if (res.data.code === 2000) {
            this.form = res.data.data
            this.$refs.wang_editor.updateInput(res.data.data.content)
          }else {
          }
        }).catch((err) =>{
        console.log("获取数据错误:",err);
      })
    },
    cancel(formName) {
      this.$refs[formName].resetFields();
      this.$refs.wang_editor.clearContent()
      this.fileList.push([])
    },

    submit() {
      this.$http.post('/hospital/add', this.form)
        .then(res => {
          console.log("-->",res)
          if (res.data.code === 2000) {
            this.$notify.success({
              title: "成功",
              message: res.data.message
            });
            this.cancel('form');
          }else {
            this.$notify.error({
              title: "错误",
              message: res.data.message
            });
          }
        }).catch((err) =>{
        console.log("提交咨询数据错误:",err);
      })
    },

  }
}


</script>
<style lang="scss" scoped>
  .el-breadcrumb{
    margin-bottom: 20px;
  }
  .container{
    margin-top: 20px;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9fafc;

  }


  .avatar-uploader {
    border: 1px dashed #d9d9d9;
    margin-top: 40px;
    border-radius: 6px;
    cursor: pointer;
    overflow: hidden;
  }
  .avatar-uploader-icon {
    display: flex;
    border: 1px dashed #d9d9d9;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    font-size: 30px;
    height: 163px;
    width: 178px;
  }
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader:hover {
    border-color: #409EFF;
  }
  .el-upload:hover {
    border-color: #409EFF;
  }

</style>
