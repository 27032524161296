
<template>
  <div>
    <!-- 面包 导航-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item >首页</el-breadcrumb-item>
      <el-breadcrumb-item>联系我们</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="container" style="height: 700px">
      <editor ref="wang_editor" v-model:value="content" ></editor>
    </div>
    <el-button type="primary" @click="submit">提交</el-button>


  </div>
</template>

<script>
import Editor from "@/components/editor";

export default {
  name: "contactMe",
  components: {Editor},
  data() {
    return {
      content:"",
    }
  },
  created() {
    this.getCurrent()
  },

  methods: {
    getCurrent(){
      this.$http.get('/concat/getone', )
        .then(res => {
          console.log("-->",res)
          if (res.data.code === 2000) {
            this.content = res.data.data.content
            this.$refs.wang_editor.updateInput(this.content)
          }else {
          }
        }).catch((err) =>{
        console.log("获取数据错误:",err);
      })
    },
    submit() {
      this.$http.post('/concat/add', {"content":this.content})
        .then(res => {
          console.log("-->",res)
          if (res.data.code === 2000) {
            this.$notify.success({
              title: "成功",
              message: res.data.message
            });
            this.cancel('form');
          }else {
            this.$notify.error({
              title: "错误",
              message: res.data.message
            });
          }
        }).catch((err) =>{
        console.log("提交咨询数据错误:",err);
      })
    },

  }
}


</script>
<style lang="scss" scoped>
  .el-breadcrumb{
    margin-bottom: 20px;
  }
  .container{
    margin-top: 20px;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9fafc;

  }



</style>
