<template>
  <div class="login_container">
    <div class="login_box">
      <div>
        <img class="left_info" src="../assets/login_left.png"/>
      </div>
      <div class="right_info">
        <img class="hospital_image" src="../assets/hospital_logo.png" >
        <div style="font-size: 23px;font-weight: 550"> 统一登录门户  </div>
        <el-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules" label-width="0px" class="login_form">
          <!-- 用户名 iconfont 使我们定义的css文件了名字 -->
          <el-form-item prop="username">
            <!-- v-model 表示双向绑定-->
            <el-input v-model="loginForm.username" style="font-size: 20px" @keyup.native.enter="login">
              <i slot="prefix" style="height:100%; display: flex; align-items: center; justify-content: center;">
                <img style="width:20px; height:20px" src="../assets/user.png" alt="" />
              </i>
            </el-input>
          </el-form-item>
          <!-- 密码 -->
          <el-form-item prop="password">
            <el-input v-model="loginForm.password" type="password" style="font-size: 20px" @keyup.native.enter="login">
              <i slot="prefix" style="height:100%; display: flex; align-items: center; justify-content: center">
                <img style="width:20px; height:20px" src="../assets/password.png" alt="" />
              </i>
            </el-input>
          </el-form-item>
          <!-- 按钮 -->
          <el-form-item class="btns">
            <el-button type="primary" @click="login" style="width: 200px; border-radius: 20px; background-color: rgb(0,122,255);">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
      },
      loginFormRules: {
        username: [
          { required: true, message: '请输入登录名称', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
        ],
      }
    };
  },
  methods: {
    resetLoginForm() {
      this.$refs.loginFormRef.resetFields();
    },
    login() {
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return;
        const { data: res } = await this.$http.post("/user/login", {
          userName: this.loginForm.username,
          password: this.loginForm.password
        });
        if (res.code !== 2000) {
          this.$message.error("登录失败！");
          return;
        }
        this.$message.success("登录成功");
        window.sessionStorage.setItem('token', res.data.token);
        window.sessionStorage.setItem("activePath", "teacherInfo");
        this.$router.push('/home');
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.login_container {
  background-image: url('../assets/background.png');
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .login_box {
    width: 900px;
    height: 500px;
    background-color: #fff;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    .left_info {
      width: 600px;
      height: 500px;
    }
    .right_info {
      width: 50%;
      height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgba(210, 235, 235, 0.947);
      .login_form {
        margin-top: 20px;
        width: 80%;
        padding: 0 20px;
        box-sizing: border-box;
        .btns {
          display: flex;
          justify-content: center;
        }
      }
      .hospital_image {
        width: 100%;
        height: 11%;
        margin-bottom: 15%;
        border-radius: 10px;
      }
    }
  }
}
</style>
